import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function commonReducer(state = initialState.commonReducer, action) {
    switch (action.type) {
        case types.SET_SERVER_ERROR:
            return Object.assign({}, state, {
                serverError: action.serverError,
            });
        case types.SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });

        default:
            return state;
    }
}
