import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, hasAccess, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                hasAccess ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />
            }
        />
    );
};

PrivateRoute.propTypes = {
    hasAccess: PropTypes.bool.isRequired,
};

export default PrivateRoute;
