import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './navBar.css';

const NavBar = ({ navLinks, children }) => {
    const links = navLinks.map(link => (
        <li className={`${s.item} ${s[link.class]}`} key={link.text}>
            <a className={`${s.link}`} href={link.href} onClick={link.onClick}>
                {link.text}
            </a>
        </li>
    ));

    return (
        <ul className={s.navBar}>
            <Fragment>
                {links}
                <li className={s.item}>{children}</li>
            </Fragment>
        </ul>
    );
};

NavBar.propTypes = {
    navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    children: PropTypes.node,
};

NavBar.defaultProps = {
    children: null,
};

export default NavBar;
