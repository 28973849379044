export const SET_USERNAME = 'SET_USERNAME';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_EXPIRATION_TIME = 'SET_EXPIRATION_TIME';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const SET_SAVED_DATA = 'SET_SAVED_DATA';
export const SET_SAVED_HAS_VALID_PROVIDER_ID = 'SET_SAVED_HAS_VALID_PROVIDER_ID';
export const SET_SAVED_RESPONSE = 'SET_SAVED_RESPONSE';
export const SET_DOMAIN_LIST = 'SET_DOMAIN_LIST';
export const SET_DOMAIN_HISTORY = 'SET_DOMAIN_HISTORY';
export const SET_TARGET_DOMAIN = 'SET_TARGET_DOMAIN';
