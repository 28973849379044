import moment from 'moment';

const formatSnakeCase = value => {
    return value.replace(/_/g, ' ');
};

const formatTimestamp = value => {
    return moment
        .utc(value)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
};

export { formatSnakeCase, formatTimestamp };
