import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Token from './Token';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconWithTooltip from '../Icon/IconWithTooltip';

import { VALIDATION_ERRORS, SPACER_KEYS, FORBIDDEN_KEYS } from '../../utils/domainMap.utils';

import s from './tokenField.css';

class TokenField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            inputError: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.removeToken = this.removeToken.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleChange(e) {
        let { value } = e.target;
        const { valueType } = this.props;
        value = value.trim();
        if (valueType === 'number') {
            value = parseInt(value, 10);
        }
        this.setState({
            inputValue: value,
        });
    }

    handleKeyDown(e) {
        const { keyCode } = e;
        const { onChange, name, values } = this.props;
        const { inputValue } = this.state;
        const newValues = [...values];

        let newInputError = '';
        this.setState({
            inputError: '',
        });

        if (keyCode === 69 || Object.values(FORBIDDEN_KEYS).includes(keyCode)) {
            e.preventDefault();
            this.setState({
                inputError: VALIDATION_ERRORS.typeError('numbers'),
            });
        }
        if (Object.values(SPACER_KEYS).includes(keyCode) && inputValue !== '') {
            if (newValues.includes(inputValue)) {
                newInputError = VALIDATION_ERRORS.repeatedValue;
                this.setState({
                    inputError: newInputError,
                });
            } else {
                newValues.push(inputValue);
                this.setState({
                    inputValue: '',
                    inputError: '',
                });

                onChange(newValues, name);
            }
        }
    }

    handleFocus(e) {
        const { onFocus } = this.props;
        onFocus(e);
    }

    removeToken(token) {
        const { values, onChange, name } = this.props;
        const newValues = values.filter(value => value.toString() !== token);
        onChange(newValues, name);
    }

    render() {
        const {
            className,
            name,
            error,
            dataCy,
            disabled,
            labelText,
            values,
            innerRef,
            description,
        } = this.props;
        let classes = `${s.tokenField}`;
        if (className) {
            classes = `${classes} ${s[className]}`;
        }

        const { inputValue, inputError } = this.state;
        const tokens = [];

        values.forEach(keyValue => {
            tokens.push(<Token value={keyValue} key={keyValue} removeToken={this.removeToken} />);
        });

        return (
            <div className={s.container} data-cy={`${dataCy}_container`} ref={innerRef}>
                <div className={s.label}>{labelText}</div>
                <div className={classes}>
                    <input
                        data-cy={dataCy}
                        disabled={disabled}
                        className={s.input}
                        autoComplete="off"
                        name={name}
                        type="number"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        value={inputValue}
                        onFocus={this.handleFocus}
                        placeholder="Type value and press tab, enter or space"
                    />
                    {tokens}

                    {inputError && <ErrorMessage message={inputError} className="fieldError" />}
                    {error && <ErrorMessage message={error} className="fieldError" />}
                </div>
                {description && (
                    <IconWithTooltip description={description} name={className} icon="help" />
                )}
            </div>
        );
    }
}

TokenField.propTypes = {
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    labelText: PropTypes.string.isRequired,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    valueType: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string || PropTypes.number),
    description: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TokenField.defaultProps = {
    className: '',
    dataCy: '',
    error: '',
    values: [],
    description: '',
    valueType: 'string',
    innerRef: null,
};

export default TokenField;
