import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectedLogin from '../components/Login/Login';
import ConnectedDomainList from '../components/DomainList/DomainList';
import ConnectedDomainEditor from '../components/DomainEditor/DomainEditor';
import ConnectedDomainUserForm from '../components/DomainUserForm/DomainUserForm';
import ConnectedDomainHistory from '../components/DomainHistory/DomainHistory';
import NoMatch from '../components/NoMatch/NoMatch';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export const Routes = props => {
    const { expiresAt, token } = props;
    const currentTimestamp = Date.now();

    const hasAccess = currentTimestamp < expiresAt && token !== '';

    return (
        <Switch>
            <PublicRoute path="/" exact {...props} component={ConnectedLogin} />
            <PrivateRoute
                path="/domains"
                exact
                {...props}
                hasAccess={hasAccess}
                component={ConnectedDomainList}
            />
            <PrivateRoute
                path="/domains/add"
                exact
                {...props}
                hasAccess={hasAccess}
                component={ConnectedDomainEditor}
            />
            <PrivateRoute
                path="/domains/:id/edit"
                exact
                {...props}
                hasAccess={hasAccess}
                component={ConnectedDomainEditor}
            />
            <PrivateRoute
                path="/domains/:id/add-user"
                exact
                {...props}
                hasAccess={hasAccess}
                component={ConnectedDomainUserForm}
            />
            <PrivateRoute
                path="/domains/:id/history"
                exact
                {...props}
                hasAccess={hasAccess}
                component={ConnectedDomainHistory}
            />
            <Route component={NoMatch} />
        </Switch>
    );
};

Routes.propTypes = {
    history: PropTypes.shape({}).isRequired,
    expiresAt: PropTypes.number,
    token: PropTypes.string.isRequired,
};

Routes.defaultProps = {
    expiresAt: null,
};

const mapStateToProps = state => {
    return {
        expiresAt: state.sessionReducer.expiresAt,
        token: state.sessionReducer.token,
    };
};

export default withRouter(connect(mapStateToProps)(Routes));
