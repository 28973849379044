import { VALIDATION_MAP, VALIDATION_ERRORS } from './domainMap.utils';
import { USER_VALIDATION_ERRORS } from './userMap.utils';
import isJSON from './JSONValidation.utils';

const validateFloat = value => {
    let error;
    if (value && (typeof value !== 'number' || value < 0)) {
        error = VALIDATION_ERRORS.typeError('numbers');
    }

    return error;
};

const validateRequired = value => {
    let error;
    if (!value) {
        error = VALIDATION_ERRORS.requiredError;
    }
    return error;
};

const validateToken = value => {
    let error;
    if (value) {
        [...value].forEach(char => {
            if (/\D/.test(char) && !/,/.test(char)) {
                error = VALIDATION_ERRORS.typeError('numbers');
            }
        });
    }

    return error;
};

const validateNumber = value => {
    let error;
    if (value) {
        [...value].forEach(char => {
            if (/\D/.test(char)) {
                error = VALIDATION_ERRORS.typeError('numbers');
            }
        });
    }

    return error;
};

const validateMaxLength = (name, value) => {
    let error;
    if (value.length > VALIDATION_MAP[name].maxLength) {
        error = VALIDATION_ERRORS.lengthError(VALIDATION_MAP[name].maxLength);
    }

    return error;
};

const validateMinMax = (name, value) => {
    let error;

    if (value > VALIDATION_MAP[name].max && value > VALIDATION_MAP[name].min) {
        error = VALIDATION_ERRORS.errorMax(VALIDATION_MAP[name].max);
    }

    if (value < VALIDATION_MAP[name].max && value < VALIDATION_MAP[name].min) {
        error = VALIDATION_ERRORS.errorMin(VALIDATION_MAP[name].min);
    }

    return error;
};

const validateJSON = value => {
    let error;

    if (!isJSON(value)) {
        error = VALIDATION_ERRORS.JSONError;
    }

    return error;
};

const validateEmail = email => {
    let error;
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(email.toLowerCase())) {
        error = USER_VALIDATION_ERRORS.emailError;
    }
    return error;
};

const validatePassword1 = password => {
    let error;
    if (password.length < 8) {
        error = USER_VALIDATION_ERRORS.passwordError;
    }
    return error;
};

const validatePassword2 = (password1, password2) => {
    let error;
    if (password1 !== password2) {
        error = USER_VALIDATION_ERRORS.repeatPasswordError;
    }
    return error;
};

export {
    validateFloat,
    validateRequired,
    validateNumber,
    validateMinMax,
    validateToken,
    validateMaxLength,
    validateJSON,
    validateEmail,
    validatePassword1,
    validatePassword2,
};
