import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './checkbox.css';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconWithTooltip from '../Icon/IconWithTooltip';
import WarningMessage from '../WarningMessage/WarningMessage';

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isInitializedChecked: false,
        };
    }

    componentDidMount() {
        const { checked } = this.props;

        if (checked) {
            this.setState({
                isInitializedChecked: true,
            });
        }
    }

    handleChange(e) {
        const { onChange } = this.props;
        onChange(e);
    }

    render() {
        const {
            checked,
            className,
            dataCy,
            disabled,
            id,
            labelText,
            error,
            innerRef,
            description,
            warningMessage,
        } = this.props;
        const { isInitializedChecked } = this.state;
        let classes = s.checkbox;
        if (className) {
            classes = `${classes} ${s[className]}`;
        }
        if (disabled) {
            classes = `${classes} ${s.disabled}`;
        }

        return (
            <div className={s.checkboxContainer} ref={innerRef} data-cy={`${dataCy}_wrapper`}>
                <label htmlFor={id} className={s.label}>
                    {labelText}
                </label>
                <input
                    id={id}
                    type="checkbox"
                    className={classes}
                    onChange={this.handleChange}
                    disabled={disabled}
                    checked={checked}
                    data-cy={dataCy}
                />
                {description && <IconWithTooltip description={description} name={id} icon="help" />}
                {!isInitializedChecked && warningMessage && (
                    <WarningMessage message={warningMessage} className="fieldWarning" />
                )}
                {error && <ErrorMessage message={error} className="fieldError" />}
            </div>
        );
    }
}

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    description: PropTypes.string,
    warningMessage: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

Checkbox.defaultProps = {
    className: '',
    dataCy: '',
    disabled: false,
    error: '',
    description: '',
    warningMessage: '',
    innerRef: null,
};

export default Checkbox;
