import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function domainReducer(state = initialState.domainReducer, action) {
    switch (action.type) {
        case types.SET_SAVED_DATA:
            return Object.assign({}, state, {
                data: action.data,
            });
        case types.SET_SAVED_RESPONSE:
            return Object.assign({}, state, {
                response: action.response,
            });
        case types.SET_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: action.isSuccess,
            });
        case types.SET_SAVED_HAS_VALID_PROVIDER_ID:
            return Object.assign({}, state, {
                hasValidProviderId: action.hasValidProviderId,
            });
        case types.SET_TARGET_DOMAIN:
            return Object.assign({}, state, {
                targetDomain: action.targetDomain,
            });

        default:
            return state;
    }
}
