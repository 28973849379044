import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconButton from '../Button/IconButton';
import IconWithTooltip from '../Icon/IconWithTooltip';

import s from './input.css';

class Input extends Component {
    constructor(props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleIconClick = this.handleIconClick.bind(this);
    }

    handleBlur(e) {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(e);
        }
    }

    handleChange(e) {
        const { onChange } = this.props;
        onChange(e);
    }

    handleIconClick(e) {
        const { onClick } = this.props;
        if (onClick) {
            onClick(e);
        }
    }

    handleFocus(e) {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus(e);
        }
    }

    handleKeyDown(e) {
        const { onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    render() {
        const {
            innerRef,
            className,
            containerClass,
            dataCy,
            disabled,
            error,
            fill,
            icon,
            labelText,
            maxLength,
            min,
            max,
            name,
            placeholder,
            required,
            type,
            value,
            description,
        } = this.props;

        let classes = `${s.input}`;
        if (className) {
            classes = `${s.input} ${s[className]}`;
        }
        if (disabled) {
            classes = `${s.input} ${s.disabled}`;
        }
        if (error) {
            classes = `${s.input} ${s.error}`;
        }

        return (
            <div className={`${s.inputContainer} ${s[containerClass]}`} ref={innerRef}>
                <label htmlFor={name} className={s.label}>
                    {labelText}
                </label>

                <input
                    key={dataCy}
                    autoComplete="off"
                    className={classes}
                    data-cy={dataCy}
                    disabled={disabled}
                    maxLength={maxLength}
                    name={name}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onKeyDown={this.handleKeyDown}
                    placeholder={placeholder}
                    required={required}
                    type={type}
                    value={value}
                    min={min}
                    max={max}
                />
                {error && (
                    <Fragment>
                        <ErrorMessage message={error} className="fieldError" />
                    </Fragment>
                )}
                {icon && (
                    <div className={s.iconButtonWrapper}>
                        <IconButton
                            dataCy={`${name}Icon`}
                            icon={icon}
                            onClick={this.handleIconClick}
                            fill={fill}
                            id={`${name}Icon`}
                        />
                    </div>
                )}
                {description && (
                    <IconWithTooltip description={description} name={name} icon="help" />
                )}
            </div>
        );
    }
}

Input.propTypes = {
    description: PropTypes.string,
    innerRef: PropTypes.shape({}),
    className: PropTypes.string,
    containerClass: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fill: PropTypes.string,
    icon: PropTypes.string,
    labelText: PropTypes.string,
    maxLength: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
    description: '',
    innerRef: null,
    className: '',
    containerClass: '',
    dataCy: '',
    disabled: false,
    error: '',
    fill: '',
    icon: '',
    labelText: '',
    maxLength: undefined,
    max: undefined,
    min: undefined,
    onBlur: undefined,
    onClick: undefined,
    onFocus: undefined,
    onKeyDown: undefined,
    placeholder: '',
    required: false,
    value: '',
};

export default Input;
