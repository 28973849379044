import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import * as sessionActions from '../../actions/sessionActions';
import * as commonActions from '../../actions/commonActions';

import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LoadingText from '../LoadingText/LoadingText';

import s from './login.css';

export class Login extends Component {
    constructor(props) {
        super(props);

        this.signIn = this.signIn.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
        this.setUpGoogleAuth = this.setUpGoogleAuth.bind(this);
    }

    componentDidMount() {
        this.setUpGoogleAuth();
    }

    onSuccess(authResult) {
        const { login, setServerErrors } = this.props;

        if (authResult.code) {
            login(authResult);
            setServerErrors('');
        }
    }

    onFailure(err) {
        const { setServerErrors } = this.props;
        setServerErrors(err);
    }

    // eslint-disable-next-line class-methods-use-this
    setUpGoogleAuth() {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/client:platform.js';
        script.onload = () => {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID_KEY,
                });
            });
        };

        document.body.appendChild(script);
    }

    signIn() {
        const auth2 = window.gapi.auth2.getAuthInstance();
        return auth2
            .grantOfflineAccess()
            .then(res => this.onSuccess(res))
            .catch(err => this.onFailure(err));
    }

    render() {
        const { serverError, isLoading } = this.props;

        return (
            <div className={s.login}>
                <div className={s.logoContainer}>
                    <div className={s.image} />
                </div>
                <Button
                    onClick={this.signIn}
                    className="loginButton"
                    dataCy="login"
                    label={isLoading ? <LoadingText message="Loading" /> : 'Login with Google'}
                />
                {Object.keys(serverError).length !== 0 && (
                    <div className={s.errorWrapper}>
                        <ErrorMessage message={serverError.footer} className="loginError" />
                    </div>
                )}

                <div className={s.footer}>© Broadsign International, LLC. All rights reserved.</div>
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    serverError: PropTypes.shape({}),
    setServerErrors: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

Login.defaultProps = {
    serverError: {},
};

const mapStateToProps = state => {
    return {
        loginError: state.sessionReducer.loginError,
        isLoading: state.commonReducer.isLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: authResult => dispatch(sessionActions.login(authResult)),
        setServerErrors: error => dispatch(commonActions.setServerErrors(error)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
