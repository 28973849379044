import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function sessionReducer(state = initialState.sessionReducer, action) {
    switch (action.type) {
        case types.SET_USERNAME:
            return Object.assign({}, state, {
                username: action.username,
            });
        case types.SET_TOKEN:
            return Object.assign({}, state, {
                token: action.token,
            });
        case types.SET_EXPIRATION_TIME:
            return Object.assign({}, state, {
                expiresAt: action.expiresAt,
            });

        default:
            return state;
    }
}
