import React, { Fragment } from 'react';
import SelectComponent from 'react-select';
import PropTypes from 'prop-types';
import s from './select.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconButton from '../Button/IconButton';
import IconWithTooltip from '../Icon/IconWithTooltip';

const Select = props => {
    const parseOptions = options => options.map(option => ({ value: option, label: option }));

    const parseDefaultValue = (defaultValue, options = []) => {
        const { multiple } = props;

        if (multiple) {
            return options.filter(({ value }) => (defaultValue || []).includes(value));
        }

        return options.find(({ value }) => value === defaultValue);
    };

    const handleChange = (obj, { name }) => {
        const { onSelect, multiple } = props;

        if (multiple) {
            const value = obj.map(element => element.value);

            onSelect({ name, value });
        } else {
            onSelect({ name, value: obj.value });
        }
    };

    const {
        className,
        dataCy,
        disabled,
        error,
        label,
        name,
        value,
        description,
        options,
        multiple,
        icon,
        fill,
        selectRef,
    } = props;

    const parsedOptions = parseOptions(options);
    const parsedDefaultValue = parseDefaultValue(value, parsedOptions);

    let classes = `${s.select}`;

    if (className !== '') {
        classes = `${classes} ${s[className]}`;
    }
    if (disabled) {
        classes = `${classes} ${s.disabled}`;
    }

    if (error) {
        classes = `${classes} ${s.error}`;
    }

    return (
        <div className={classes} data-cy={dataCy}>
            <label htmlFor={name} className={s.label}>
                {label}
            </label>

            <SelectComponent
                ref={selectRef}
                options={parsedOptions}
                isMulti={multiple}
                isDisabled={disabled}
                onChange={handleChange}
                defaultValue={parsedDefaultValue}
                className={s.dropdown}
                name={name}
            />
            {error && (
                <Fragment>
                    <ErrorMessage message={error} className="fieldError" />
                </Fragment>
            )}
            {icon && (
                <div className={s.iconButtonWrapper}>
                    <IconButton
                        dataCy={`${name}Icon`}
                        icon={icon}
                        fill={fill}
                        id={`${name}Icon`}
                        onClick={() => ({})}
                    />
                </div>
            )}
            {description && <IconWithTooltip description={description} name={name} icon="help" />}
        </div>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOf(PropTypes.string, PropTypes.arrayOf(PropTypes.string)),
    description: PropTypes.string,
    multiple: PropTypes.bool,
    fill: PropTypes.string,
    icon: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    selectRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

Select.defaultProps = {
    className: '',
    dataCy: '',
    disabled: false,
    error: '',
    value: [],
    description: '',
    multiple: false,
    fill: '',
    icon: '',
    innerRef: {},
    selectRef: {},
};

export default Select;
