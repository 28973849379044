import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableCell from './TableCell';
import TableAction from './TableAction';
import List from '../List/List';

import s from './tableRow.css';
import * as formatUtils from '../../utils/format.utils';

class TableRow extends Component {
    static parseValueType(type, value, label) {
        if (type === 'Timestamp') {
            const parsedValue =
                label === 'creation_tm'
                    ? `Created on ${formatUtils[`format${type}`](value)}`
                    : `${formatUtils[`format${type}`](value)}`;

            return parsedValue;
        }

        if (type === 'Object') {
            return <List items={value} />;
        }

        return formatUtils[`format${type}`](value);
    }

    constructor(props) {
        super(props);

        this.renderActionCell = this.renderActionCell.bind(this);
        this.renderCell = this.renderCell.bind(this);
    }

    renderCell() {
        const { data, fields } = this.props;
        const dataFields = fields.filter(field => !field.actions);

        const cell = dataFields.map(field => (
            <TableCell
                key={field.identifier}
                data={
                    field.valueType
                        ? TableRow.parseValueType(field.valueType, data[field.name], field.label)
                        : data[field.name]
                }
                label={
                    field.labelType
                        ? TableRow.parseValueType(field.labelType, data[field.label], field.label)
                        : data[field.label]
                }
                name={field.identifier}
                cellType={field.cellType}
            />
        ));

        return cell;
    }

    renderActionCell(actionFields) {
        const { data } = this.props;
        const { actions } = actionFields;

        const actionCell = actions.map(action => (
            <TableAction
                key={action.type}
                onClick={() => action.handler(data)}
                label={action.label}
                action={action.type}
                fill={action.fill}
            />
        ));

        return actionCell;
    }

    render() {
        const { data, fields } = this.props;
        const actionFields = fields.filter(field => field.actions);

        const cell = this.renderCell();
        const actionCell = actionFields.length > 0 ? this.renderActionCell(...actionFields) : null;
        const classes = `${s.row} ${data.name}`;

        return (
            <tr className={classes}>
                {cell}
                {actionCell}
            </tr>
        );
    }
}

TableRow.propTypes = {
    data: PropTypes.shape({}).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TableRow;
