import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './button.css';

class Button extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { disabled, onClick } = this.props;
        if (disabled) {
            e.preventDefault();
            return;
        }

        onClick();
    }

    render() {
        const { dataCy, disabled, label, children, className } = this.props;
        let classes = `${s.button}`;

        if (className) {
            classes = `${classes} ${s[className]}`;
        }
        if (disabled) {
            classes = `${classes} ${s.disabled}`;
        }

        return (
            <button
                data-cy={dataCy}
                type="button"
                className={classes}
                onClick={this.handleClick}
                disabled={disabled}
            >
                {children}
                {label}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
    dataCy: '',
    disabled: false,
    children: undefined,
    className: '',
    label: '',
};

export default Button;
