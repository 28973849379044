import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './token.css';

class Token extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { removeToken } = this.props;
        const value = e.target.parentElement.innerText.slice(0, -1);
        removeToken(value);
    }

    render() {
        const { value } = this.props;
        return (
            <p className={s.token}>
                {value}
                <button
                    type="button"
                    className={s.removeToken}
                    onClick={this.handleClick}
                    data-cy="removeToken"
                >
                    x
                </button>
            </p>
        );
    }
}

Token.propTypes = {
    value: PropTypes.string.isRequired,
    removeToken: PropTypes.func.isRequired,
};

export default Token;
