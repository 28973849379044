import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    EditorState,
    ContentState,
    convertToRaw,
} from 'draft-js'; /* repo: https://github.com/facebook/draft-js */
import { Editor } from 'react-draft-wysiwyg'; /* repo: https://github.com/jpuri/react-draft-wysiwyg */
import draftToHtml from 'draftjs-to-html'; /* repo: https://github.com/jpuri/draftjs-to-html#readme */
import htmlToDraft from 'html-to-draftjs';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconWithTooltip from '../Icon/IconWithTooltip';

import './react-draft-wysiwyg.css';
import s from './textEditor.css';

class TextEditor extends Component {
    constructor(props) {
        super(props);

        if (props.defaultText) {
            this.state = {
                editorState: EditorState.createWithContent(
                    ContentState.createFromBlockArray(htmlToDraft(props.defaultText))
                ),
            };
        } else {
            this.state = {
                editorState: EditorState.createEmpty(),
            };
        }

        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    onEditorStateChange(editorState) {
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({
            editorState,
        });

        this.handleChange(value);
    }

    handleChange(value) {
        const { onChange, className } = this.props;
        onChange(className, value);
    }

    handleFocus(e) {
        const { onFocus, className } = this.props;
        e.target.name = className;
        onFocus(e);
    }

    handleBlur(e) {
        const { onBlur, className } = this.props;
        e.target.name = className;
        onBlur(e);
    }

    render() {
        const { labelText, className, dataCy, disabled, error, innerRef, description } = this.props;
        const { editorState } = this.state;
        let classes = `${s.textEditor}`;
        if (className) {
            classes = `${classes} ${s[className]}`;
        }

        return (
            <div className={classes} data-cy={dataCy} ref={innerRef}>
                <div className={s.label}>{labelText}</div>
                <Editor
                    readOnly={disabled}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    editorState={editorState}
                    wrapperClassName={s.textEditorWrapper}
                    editorClassName={s.textEditorArea}
                    toolbarClassName={s.textEditorToolbar}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign'],
                    }}
                />
                {error && <ErrorMessage message={error} className="fieldError" />}
                {description && (
                    <IconWithTooltip description={description} name={className} icon="help" />
                )}
            </div>
        );
    }
}

TextEditor.propTypes = {
    className: PropTypes.string.isRequired,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    labelText: PropTypes.string.isRequired,
    error: PropTypes.string,
    defaultText: PropTypes.string,
    description: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TextEditor.defaultProps = {
    dataCy: '',
    error: '',
    defaultText: '',
    description: '',
    innerRef: null,
};

export default TextEditor;
