import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function getDomainsReducer(state = initialState.getDomainsReducer, action) {
    switch (action.type) {
        case types.SET_DOMAIN_LIST:
            return Object.assign({}, state, {
                domains: action.domains,
            });
        case types.SET_DOMAIN_HISTORY:
            return Object.assign({}, state, {
                domainHistory: action.domainHistory,
            });

        default:
            return state;
    }
}
