import React from 'react';
import PropTypes from 'prop-types';

import s from './errorMessage.css';

const ErrorMessage = props => {
    const { message, className } = props;
    let classes = `${s.errorMessage}`;
    if (className) {
        classes = `${s.errorMessage} ${s[className]}`;
    }
    return (
        <div className={classes} data-cy="errorMessage">
            {message}
        </div>
    );
};

ErrorMessage.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
};

ErrorMessage.defaultProps = {
    className: '',
};

export default ErrorMessage;
