import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';

import s from './pagination.css';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pager: {},
        };
    }

    componentDidMount() {
        this.setPage(1);
    }

    setPage(page) {
        const { onPageChange } = this.props;

        const newPager = this.getPager(page);

        this.setState({ pager: newPager });
        onPageChange(page);
    }

    getPager(currentPage) {
        const { totalPages } = this.props;
        const maxShowablePages = 5;

        let startPage;
        let endPage;
        const pages = [];

        if (totalPages <= maxShowablePages) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= maxShowablePages - 2) {
            startPage = 1;
            endPage = maxShowablePages;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - (maxShowablePages - 1);
            endPage = totalPages;
        } else {
            startPage = currentPage - (maxShowablePages - 3);
            endPage = currentPage + (maxShowablePages - 3);
        }

        for (let i = 0; i <= endPage - startPage; i += 1) {
            pages.push(startPage + i);
        }

        return {
            currentPage,
            totalPages,
            startPage,
            endPage,
            pages,
        };
    }

    render() {
        const { pager } = this.state;
        const { className, disabled } = this.props;

        const baseClassName = 'pageButton';

        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }

        return (
            <div className={`${s.container} ${s[className]}`}>
                <Button
                    disabled={pager.currentPage === 1 || disabled}
                    onClick={() => this.setPage(1)}
                    className={baseClassName}
                    dataCy="pageButtonFirst"
                >
                    First
                </Button>

                <Button
                    disabled={pager.currentPage === 1 || disabled}
                    onClick={() => this.setPage(pager.currentPage - 1)}
                    className={baseClassName}
                    dataCy="pageButtonPrevious"
                >
                    Previous
                </Button>

                {pager.pages.map(page => (
                    <Button
                        disabled={disabled}
                        key={page}
                        onClick={() => this.setPage(page)}
                        className={
                            page === pager.currentPage ? 'pageButtonActive' : `${baseClassName}`
                        }
                        dataCy={`pageButton${page}`}
                    >
                        {page}
                    </Button>
                ))}

                <Button
                    disabled={pager.currentPage >= pager.totalPages || disabled}
                    onClick={() => this.setPage(pager.currentPage + 1)}
                    dataCy="pageButtonNext"
                    className={baseClassName}
                >
                    Next
                </Button>

                <Button
                    disabled={pager.currentPage === pager.totalPages || disabled}
                    onClick={() => this.setPage(pager.totalPages)}
                    className={baseClassName}
                    dataCy="pageButtonLast"
                >
                    Last
                </Button>
            </div>
        );
    }
}

Pagination.propTypes = {
    className: PropTypes.string,
    totalPages: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
    className: '',
    totalPages: 1,
};

export default Pagination;
