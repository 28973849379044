import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';

import s from './tableCell.css';

class TableCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({
            isHovered: true,
        });
    }

    handleMouseLeave() {
        this.setState({
            isHovered: false,
        });
    }

    render() {
        const { isHovered } = this.state;
        const { data, label, name, cellType } = this.props;
        const classes = `${s.dataCell} ${s[cellType]} ${s[name]}`;

        return (
            <td
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                className={classes}
                data-cy={name}
            >
                {data}
                {label && isHovered && <Tooltip text={label} />}
            </td>
        );
    }
}

TableCell.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.node,
    label: PropTypes.string,
    cellType: PropTypes.string,
};

TableCell.defaultProps = {
    label: '',
    cellType: '',
    data: undefined,
};

export default TableCell;
