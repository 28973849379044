import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import Tooltip from '../Tooltip/Tooltip';

import s from './iconWithTooltip.css';
import variables from '../../css/variables';

class IconWithTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({
            isHovered: true,
        });
    }

    handleMouseLeave() {
        this.setState({
            isHovered: false,
        });
    }

    render() {
        const { description, name, icon, fill } = this.props;
        const { isHovered } = this.state;

        return (
            <div
                className={s.iconContainer}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                data-cy={`${name}Description`}
            >
                <Icon icon={icon} fill={fill} />
                {isHovered && description && <Tooltip text={description} />}
            </div>
        );
    }
}

IconWithTooltip.propTypes = {
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string,
    fill: PropTypes.string,
};

IconWithTooltip.defaultProps = {
    name: '',
    fill: variables.borderInputFocus,
};

export default IconWithTooltip;
