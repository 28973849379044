import axios from 'axios';
import history from '../history/history';
import * as actions from './actionTypes';
import { baseHeaderWithAuth, baseHeader, loginURL, logoutURL } from '../utils/url.utils';
import { beginLoading, endLoading, setServerErrors } from './commonActions';
import store from '../store/store';

const setUsername = username => {
    return {
        type: actions.SET_USERNAME,
        username,
    };
};

const setToken = token => {
    return {
        type: actions.SET_TOKEN,
        token,
    };
};

const setExpirationTime = expiresAt => {
    return {
        type: actions.SET_EXPIRATION_TIME,
        expiresAt,
    };
};

const logout = () => {
    const { token } = store.getState().sessionReducer;
    sessionStorage.removeItem('token');
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .get(`${logoutURL}`, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 200) {
                    dispatch(setUsername(''));
                    dispatch(setToken(''));
                    dispatch(setExpirationTime(null));
                    dispatch(endLoading());
                    history.push('/');
                } else {
                    dispatch(setServerErrors('Logout Failed'));
                    dispatch(endLoading());
                }
            })
            .catch(error => {
                dispatch(setServerErrors(error));
                dispatch(endLoading());
            });
    };
};

const login = authResult => {
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .post(`${loginURL}`, authResult, { headers: baseHeader })
            .then(response => {
                if (response.status === 200 && response.data.access_token) {
                    const session = {
                        username: response.data.user_informations.given_name,
                        token: response.data.access_token,
                        expiresAt: Date.parse(response.data.expiring_time),
                    };
                    dispatch(setToken(session.token));
                    dispatch(setUsername(session.username));
                    dispatch(setExpirationTime(session.expiresAt));
                    dispatch(endLoading());
                    sessionStorage.setItem('token', JSON.stringify(session));
                    history.push('/domains');
                } else {
                    dispatch(setServerErrors('Login Failed'));
                    dispatch(setToken(''));
                    dispatch(setExpirationTime(null));
                    dispatch(setUsername(''));
                    dispatch(endLoading());
                }
            })
            .catch(error => {
                dispatch(setToken(''));
                dispatch(setUsername(''));
                dispatch(setExpirationTime(null));
                dispatch(setServerErrors(error));
                dispatch(endLoading());
            });
    };
};

export { setUsername, setToken, setExpirationTime, logout, login };
