import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as sessionActions from './actions/sessionActions';

import ConnectedRoutes from './routing/Routes';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';

import NavBar from './components/NavBar/NavBar';
import LoadingText from './components/LoadingText/LoadingText';

import s from './App.css';

export class App extends Component {
    constructor(props) {
        super(props);

        this.getSessionState = this.getSessionState.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        const userSession = JSON.parse(sessionStorage.getItem('token'));
        if (userSession && userSession.token && userSession.expiresAt) {
            this.getSessionState(userSession);
        }
    }

    getSessionState(session) {
        const { setUsername, setToken, setExpirationTime, history } = this.props;
        const currentTimestamp = Date.now();

        if (currentTimestamp < session.expiresAt) {
            setToken(session.token);
            setUsername(session.username);
            setExpirationTime(session.expiresAt);
            history.push('/domains');
        } else {
            setToken('');
            setUsername('');
            setExpirationTime(null);
            history.push('/');
            sessionStorage.removeItem('token');
        }
    }

    handleLogout() {
        const { logout } = this.props;

        logout();
    }

    render() {
        const { username, isLoading, history } = this.props;

        const navLinks = [
            {
                text: `Hello, ${username}`,
                href: '/',
                class: 'username',
            },
            {
                text: 'Logout',
                class: 'logout',
                onClick: this.handleLogout,
            },
        ];

        return (
            <div className={s.app}>
                {history.location.pathname !== '/' && (
                    <Fragment>
                        <NavBar navLinks={navLinks}>
                            <Breadcrumbs />
                        </NavBar>
                        {isLoading && <LoadingText message="Loading" className="screen" />}
                    </Fragment>
                )}

                <ConnectedRoutes />
            </div>
        );
    }
}

App.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    setUsername: PropTypes.func.isRequired,
    setExpirationTime: PropTypes.func.isRequired,
    username: PropTypes.string,
    history: PropTypes.shape({}).isRequired,
};

App.defaultProps = {
    username: '',
};

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.isLoading,
        username: state.sessionReducer.username,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(sessionActions.logout()),
        setUsername: username => dispatch(sessionActions.setUsername(username)),
        setToken: token => dispatch(sessionActions.setToken(token)),
        setExpirationTime: expiresAt => dispatch(sessionActions.setExpirationTime(expiresAt)),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
