import isJSON from './JSONValidation.utils';

let url = '';

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
    url = `${process.env.REACT_APP_API_URL}`;
}

if (window.Cypress) {
    url = Cypress.env('API_URL');
}

const baseURL = url;

const baseHeaderWithAuth = token => {
    return {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer Django ${token}`,
    };
};

const baseHeader = { 'X-Requested-With': 'XMLHttpRequest' };

const domainURL = `${baseURL}/domain/`;
const providerURL = `${baseURL}/provider/`;
const userURL = `${baseURL}/user/`;
const loginURL = `${baseURL}/login/`;
const logoutURL = `${baseURL}/logout/`;

const errorCodes = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    409: 'Conflict',
    413: 'Payload Too Large',
    500: 'Internal Server Error',
    502: 'Bad gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
};

const parseRequestResponse = serverError => {
    const error = {};
    const newError = serverError.request ? serverError.request.response : serverError;

    if (isJSON(newError)) {
        const parsedErrors = JSON.parse(newError);

        Object.keys(parsedErrors).forEach(key => {
            error[key] = Array.isArray(parsedErrors[key])
                ? parsedErrors[key][0].replace(/_/g, ' ')
                : parsedErrors[key];
        });
    }

    if (serverError !== '') {
        error.footer = serverError.request
            ? `${serverError.message} (${errorCodes[serverError.request.status] || 'Error'})`
            : serverError.error || 'Error on Processing Request';
    }

    return error;
};

export {
    baseURL,
    domainURL,
    providerURL,
    userURL,
    loginURL,
    logoutURL,
    baseHeader,
    baseHeaderWithAuth,
    parseRequestResponse,
};
