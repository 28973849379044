import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import s from './domainUserForm.css';

import * as domainActions from '../../actions/domainActions';
import * as commonActions from '../../actions/commonActions';

import Input from '../Input/Input';
import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Modal from '../Modal/Modal';

import { USER_VALIDATION_MAP, ROLE_OPTIONS } from '../../utils/userMap.utils';
import * as validationUtils from '../../utils/validation.utils';
import Select from '../Select/Select';

export class DomainUserForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.handleBlur = this.handleBlur.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDisplayData = this.handleDisplayData.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleServerErrors = this.handleServerErrors.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.generateComponents = this.generateComponents.bind(this);
        this.getInputErrors = this.getInputErrors.bind(this);
        this.togglePasswordView = this.togglePasswordView.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    getInitialState() {
        const state = {
            errors: {},
            viewPassword: false,
            target: '',
            focusedElement: '',
        };

        const keys = Object.keys(USER_VALIDATION_MAP);
        keys.forEach(key => {
            state[key] = '';
        });

        return state;
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnter);
    }

    componentWillUnmount() {
        const { setServerErrors } = this.props;
        setServerErrors('');
        document.removeEventListener('keydown', this.handleEnter);
    }

    getInputErrors(name, value) {
        const errors = {};
        const validationKeys = Object.values(USER_VALIDATION_MAP[name].validation);
        const { password1 } = this.state;

        validationKeys.forEach(key => {
            const validationMethod = `validate${key}`;
            if (key === 'Password2' && validationUtils.validatePassword2(password1, value)) {
                errors[name] = validationUtils.validatePassword2(password1, value);
            }
            if (key !== 'Password2' && validationUtils[validationMethod](value)) {
                errors[name] = validationUtils[validationMethod](value);
            }
        });

        this.setState({
            errors,
        });

        return errors;
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            errors: {},
        });
    }

    handleCancel() {
        const { history, setServerErrors } = this.props;
        history.push('/domains');
        setServerErrors('');
    }

    handleBlur(e) {
        const { name, value } = e.target;
        this.getInputErrors(name, value);

        this.setState({
            focusedElement: '',
        });
    }

    handleEnter(e) {
        const { focusedElement } = this.state;

        if (e.keyCode === 13) {
            if (focusedElement === '') {
                this.handleSubmit();
            } else {
                this.handleBlur(e);
                e.target.blur();
            }
        }
    }

    handleFocus(e) {
        const { name } = e.target;
        this.setState({
            focusedElement: name,
        });
    }

    handleSubmit() {
        const { name, email, password1, role, phone } = this.state;
        const { targetDomain } = this.props;

        if (this.validateForm()) {
            const data = {
                email,
                password: password1,
                phone,
                name,
                role,
                domain_id: targetDomain.id,
            };
            data.role = Object.keys(ROLE_OPTIONS).find(option => ROLE_OPTIONS[option] === role);

            this.save(data);
        }
    }

    handleSelect({ name, value }) {
        this.setState({
            [name]: value,
            errors: {},
        });
    }

    validateForm() {
        const keys = Object.keys(USER_VALIDATION_MAP);

        const errors = {};
        const { ...state } = this.state;

        keys.forEach(key => {
            if (this.getInputErrors(key, state[key])[key]) {
                errors[key] = this.getInputErrors(key, state[key])[key];
            }
        });

        this.setState({
            errors,
        });

        return Object.keys(errors).length === 0;
    }

    save(data) {
        const { addUser } = this.props;
        addUser(data);
    }

    togglePasswordView(e) {
        const target = e.target.id || e.target.parentNode.id || e.target.parentNode.parentNode.id;

        this.setState(prevState => ({
            target,
            viewPassword: !prevState.viewPassword,
        }));
    }

    closeModal() {
        const { resetForm, history } = this.props;
        history.push('/domains');
        resetForm();
    }

    generateComponents() {
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const keys = Object.keys(USER_VALIDATION_MAP);
        const components = [];

        keys.forEach(key => {
            let { type } = USER_VALIDATION_MAP[key];

            if (
                USER_VALIDATION_MAP[key].type === 'password' &&
                state.viewPassword &&
                state.target.includes(key)
            ) {
                type = 'text';
            }

            if (key === 'role') {
                components.push(
                    <Select
                        innerRef={this[`ref_${key}`]}
                        key={key}
                        dataCy={key}
                        className={key}
                        description={USER_VALIDATION_MAP[key].description}
                        label={USER_VALIDATION_MAP[key].label}
                        options={USER_VALIDATION_MAP[key].options}
                        onSelect={this.handleSelect}
                        name={key}
                        required={USER_VALIDATION_MAP[key].required}
                        value={state[key]}
                        error={state.errors[key] || serverError[key]}
                        onClickOutside={this.handleBlur}
                        disabled={isLoading}
                    />
                );
            } else {
                components.push(
                    <Input
                        key={key}
                        dataCy={key}
                        className={key}
                        type={type}
                        name={key}
                        labelText={USER_VALIDATION_MAP[key].label}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        required={USER_VALIDATION_MAP[key].required}
                        value={state[key]}
                        error={
                            key === 'password1'
                                ? state.errors[key] || serverError.password
                                : state.errors[key] || serverError[key]
                        }
                        disabled={isLoading}
                        icon={USER_VALIDATION_MAP[key].type === 'password' ? 'view' : ''}
                        onClick={
                            USER_VALIDATION_MAP[key].type === 'password'
                                ? this.togglePasswordView
                                : undefined
                        }
                    />
                );
            }
        });

        return components;
    }

    handleDisplayData() {
        const { data, targetDomain } = this.props;
        const renderData = [
            <div className={s.confirmationTitle} key="confirmationTitle">
                {`User Created for Domain "${targetDomain.name}"`}
            </div>,
        ];

        const newData = Object.keys(data).filter(key => key !== 'id');

        newData.forEach(key => {
            let value = `${data[key]}`;
            if (data[key] === '') {
                value = 'Not specified';
            }

            if (key === 'password') {
                const newValue = '*';
                value = newValue.repeat(value.length);
            }
            renderData.push(
                <div className={s.confirmationItem} key={key}>
                    {key}: {value}
                </div>
            );
        });

        return renderData;
    }

    handleServerErrors() {
        const { serverError } = this.props;

        const errors = {};
        Object.keys(serverError).forEach(key => {
            errors[key] = serverError[key][0].replace(/_/g, ' ');
        });
    }

    render() {
        const { isLoading, serverError, data, isSuccess, targetDomain } = this.props;
        const { errors } = this.state;
        const components = this.generateComponents();
        const renderData = this.handleDisplayData();

        return (
            <div className={s.domainUserFormWrapper}>
                <div className={s.domainUserForm}>
                    <div className={s.titleWrapper}>
                        {`Add user to domain ${targetDomain.name} (Provider ID: ${
                            targetDomain.provider_domain_id
                        }`}
                        )
                    </div>
                    <form
                        className={s.addUserForm}
                        data-cy="domainUserForm"
                        id="domainUserForm"
                        tabIndex="-1"
                    >
                        {components}
                        <div className={s.footer}>
                            <div className={s.requiredMessage}>* Required Fields</div>
                            {errors.footer && <ErrorMessage message={errors.footer} />}
                            {Object.keys(serverError).length !== 0 && (
                                <ErrorMessage message={serverError.footer} />
                            )}
                            <div className={s.footerActions}>
                                <Button
                                    className="cancel"
                                    type="submit"
                                    label="Cancel"
                                    onClick={this.handleCancel}
                                    disabled={isLoading}
                                    dataCy="cancel"
                                />
                                <Button
                                    className="submit"
                                    type="submit"
                                    label="Submit"
                                    onClick={this.handleSubmit}
                                    disabled={isLoading}
                                    dataCy="submit"
                                />
                            </div>
                        </div>
                        {Object.keys(data).length !== 0 && isSuccess && (
                            <Modal onClose={this.closeModal}>{renderData}</Modal>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

DomainUserForm.propTypes = {
    data: PropTypes.shape({}),
    targetDomain: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    history: PropTypes.shape({}).isRequired,
    setServerErrors: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    serverError: PropTypes.shape({}),
    resetForm: PropTypes.func.isRequired,
};

DomainUserForm.defaultProps = {
    serverError: {},
    data: {},
};

const mapStateToProps = state => {
    return {
        data: state.domainReducer.data,
        isSuccess: state.domainReducer.isSuccess,
        serverError: state.commonReducer.serverError,
        isLoading: state.commonReducer.isLoading,
        targetDomain: state.domainReducer.targetDomain,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: (data, id) => dispatch(domainActions.addUser(data, id)),
        resetForm: () => dispatch(domainActions.resetForm()),
        setServerErrors: errors => dispatch(commonActions.setServerErrors(errors)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainUserForm);
