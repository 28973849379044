import React, { Fragment } from 'react';
import { NavLink, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import s from './breadcrumbsItem.css';

const breadcrumbMap = {
    domains: 'Domains',
    edit: 'Edit Domain',
    add: 'Add Domain',
    history: 'Domain History',
    'add-user': 'Add Domain User',
};

const BreadcrumbsItem = ({ match }) => (
    <Fragment>
        {breadcrumbMap[match.params.path] && (
            <NavLink
                to={match.url || ''}
                className={match.isExact ? `${s.active} ${s.breadcrumb}` : s.breadcrumb}
            >
                {breadcrumbMap[match.params.path]}
            </NavLink>
        )}

        <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
    </Fragment>
);

BreadcrumbsItem.propTypes = {
    match: PropTypes.shape({}).isRequired,
};

export default BreadcrumbsItem;
