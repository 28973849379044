import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import s from './iconButton.css';

class IconButton extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { disabled, onClick } = this.props;
        if (disabled) {
            e.preventDefault();
            return;
        }

        onClick(e);
    }

    render() {
        const { dataCy, disabled, className, icon, id, fill } = this.props;
        let classes = `${s.iconButton}`;

        if (className) {
            classes = `${classes} ${s[className]}`;
        }

        if (disabled) {
            classes = `${classes} ${s.disabled}`;
        }

        return (
            <button
                data-cy={dataCy}
                type="button"
                className={classes}
                onClick={this.handleClick}
                disabled={disabled}
                id={id || null}
            >
                <Icon icon={icon} fill={fill} />
            </button>
        );
    }
}

IconButton.propTypes = {
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string,
    fill: PropTypes.string,
};

IconButton.defaultProps = {
    dataCy: '',
    disabled: false,
    className: '',
    id: '',
    fill: '',
};

export default IconButton;
