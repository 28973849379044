import timezones from './timezones.utils';

const BILLING_TYPE_OPTIONS = {
    production: 'Production',
    evaluation_account: 'Evaluation Account',
    broadsign_use: 'Broadsign Use',
    no_access: 'No Access',
};

const PRIMARY_CURRENCY_OPTIONS = {
    no_currency: '',
    australian_dollar: 'AUD',
    canadian_dollar: 'CAD',
    euro: 'EUR',
    united_states_dollar: 'USD',
};

const ADDITIONAL_CURRENCIES_OPTIONS = {
    ...PRIMARY_CURRENCY_OPTIONS,
    british_pound_sterling: 'GBP',
};

const CMS_OPTIONS = {
    control: 'Control',
    other: 'Other',
};

const PRODUCTION_DSP_SCOPES_OPTIONS = {
    broadsign_auto_bidder: 'Broadsign Auto-Bidder',
    apac: 'APAC',
    emea: 'EMEA',
    latam: 'LATAM',
    north_america: 'North America',
    worldwide: 'Worldwide',
};

const VALIDATION_MAP = {
    id: {
        label: 'Direct Domain ID',
        required: false,
        type: 'number',
        defaultOption: '',
        validation: [],
    },
    name: {
        label: 'Name',
        required: true,
        type: 'text',
        maxLength: 512,
        defaultOption: '',
        validation: ['Required', 'MaxLength'],
    },
    cms: {
        label: 'CMS',
        required: true,
        type: 'enum',
        defaultOption: CMS_OPTIONS.control,
        options: Object.values(CMS_OPTIONS),
        validation: [],
    },
    provider_domain_id: {
        label: 'Control Domain ID',
        required: false,
        type: 'number',
        defaultOption: '',
        validation: ['Number'],
        description: 'The BSS ID for the domain.',
    },
    static_inventory: {
        label: 'Create Static Inventory',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
    },
    digital_inventory: {
        label: 'Create Digital Inventory',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
    },
    billing_type: {
        label: 'Direct Account Type',
        required: true,
        type: 'enum',
        defaultOption: '',
        options: Object.values(BILLING_TYPE_OPTIONS),
        validation: ['Required'],
    },
    timezone: {
        label: 'Timezone',
        required: true,
        type: 'enum',
        defaultOption: '',
        options: timezones,
        validation: ['Required'],
    },
    primary_currency: {
        label: 'Primary Currency',
        required: false,
        type: 'enum',
        defaultOption: null,
        options: Object.values(PRIMARY_CURRENCY_OPTIONS),
        description:
            'Please provide the main currency of that customer. This will be used across different products' +
            ' (Reach, Polaris, Direct, Static). If your customer’s main currency is not in the list and that the customer' +
            ' doesn’t have Reach or Polaris, leave that field empty and use the other field below to add a currency sign' +
            ' in Direct and/or Static',
        validation: [],
    },
    additional_currencies: {
        label: 'Additional Currencies',
        required: false,
        type: 'enum',
        defaultOption: [],
        multiple: true,
        options: Object.values(ADDITIONAL_CURRENCIES_OPTIONS)
            .slice(1)
            .sort(),
        description:
            'Please provide additional currencies that customer accepts for programmatic transactions.',
        validation: [],
    },
    default_sov_value: {
        label: 'Default SOV Value',
        required: false,
        type: 'float',
        defaultOption: 16.67,
        min: 0.01,
        max: 100,
        validation: ['MinMax', 'Float'],
        description: 'The domain share of voice default value (%)',
        direct: true,
    },
    default_slot_duration: {
        label: 'Default Slot Duration',
        required: false,
        type: 'float',
        defaultOption: 15,
        min: 0.01,
        max: 3600,
        validation: ['MinMax', 'Float'],
        description: 'The domain slot duration default value (seconds)',
        direct: true,
    },
    underperforming_rebalancing_threshold_pct: {
        label: 'Underperforming Rebalancing Threshold Percentage',
        required: false,
        type: 'float',
        defaultOption: null,
        validation: ['Float'],
        description: 'The domain underperforming rebalancing threshold value (%)',
        direct: true,
    },
    overperforming_rebalancing_threshold_pct: {
        label: 'Overperforming Rebalancing Threshold Percentage',
        required: false,
        type: 'float',
        defaultOption: null,
        validation: ['Float'],
        description: 'The domain overperforming rebalancing threshold value (%)',
        direct: true,
    },
    react_inventory_enabled: {
        label: 'React Digital Inventory',
        required: false,
        type: 'boolean',
        defaultOption: true,
        validation: [],
        unsafe: true,
        unsafeReason:
            'Allows users to use the new digital inventory page and all its added features. It will deactivate the old digital inventory page',
    },
    cpm_override: {
        label: 'CPM Override',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        unsafe: true,
        unsafeReason:
            'Allows users to enter custom CPM value, interfering with default calculations in Direct. Please communicate with the Product Owner',
    },
    calculate_screen_contribution: {
        label: 'Calculate Screen Contribution',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description: 'Enables or disables the screen contribution calculation for the domain.',
        direct: true,
    },
    allow_explicit_undersaturation: {
        label: 'Allow Explicit Undersaturation',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        unsafe: true,
        unsafeReason:
            'Allows fractional play per loop  of value less than 1.0 in Digital Package and Inventory. Used only by Westfield. Breaks how the saturation is displayed in Direct.',
    },
    bypass_google_address: {
        label: 'Bypass Google Address',
        required: false,
        type: 'boolean',
        defaultOption: true,
        checked: 'checked',
        validation: [],
        description: 'Bypass the Google address lookup for addresses.',
    },
    enable_audit_log: {
        label: 'Enable Audit Log',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description: 'Enables or disables audit logs on the domain.',
    },
    enable_integration_settings: {
        label: 'Enable Salesforce Integration',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        children: ['salesforce_instance', 'listeners'],
        direct: true,
    },
    salesforce_instance: {
        label: 'Salesforce Instance',
        required: true,
        type: 'text',
        defaultOption: '',
        validation: [],
        parent: 'enable_integration_settings',
        direct: true,
    },
    listeners: {
        label: 'Listeners',
        required: true,
        type: 'textarea',
        defaultOption: JSON.stringify([
            {
                event: 'proposal_status_change',
                processors: ['salesforce_integration'],
            },
            {
                event: 'proposal_done',
                processors: ['salesforce_integration'],
            },
        ]),
        validation: [],
        parent: 'enable_integration_settings',
        direct: true,
    },
    whitelist_containers: {
        label: 'Whitelist Containers',
        required: false,
        type: 'listOfNumbers',
        defaultOption: [],
        validation: ['Token'],
        description:
            'Control Display Unit, Criteria Folder and children that will have the content synched to Direct.',
    },
    reservation_category_blacklist: {
        label: 'Reservation Category Blacklist',
        required: false,
        type: 'listOfNumbers',
        defaultOption: [],
        validation: ['Token'],
        valueType: 'number',
        description:
            'Campaigns in Control associated with this list of Availability Categories will be blacklisted and will not appear in Direct.',
        direct: true,
    },
    skin_filter_criteria_ids: {
        label: 'Skin Filter Criteria IDs',
        required: false,
        type: 'listOfNumbers',
        defaultOption: [],
        validation: ['Token'],
        description:
            'Set a criterion on the main frame (sellable frame), then include it in the skin_filter_criteria ' +
            'Frames with corresponding criteria IDs are whitelisted and are imported in Broadsign Direct as a screen.',
    },
    network_criteria_containers: {
        label: 'Network Criteria Containers',
        required: false,
        type: 'listOfNumbers',
        defaultOption: [],
        validation: ['Token'],
        description:
            "Criteria belonging to the whitelisted folder IDs will be displayed in the Network column of Direct's " +
            'inventory page for groups only to identify the network they belong to.',
    },
    toc: {
        label: 'Terms and Conditions',
        required: false,
        type: 'html',
        defaultOption: '',
        validation: [],
        direct: true,
    },
    expiration_date: {
        label: 'Expiration Date',
        required: false,
        type: 'datePicker',
        defaultOption: null,
        validation: [],
        description:
            "Set an expiration date to the domain: users won't be able to access Direct from the specifie date.",
        direct: true,
    },
    proposal_item_name_template: {
        label: 'Proposal Item Name Template',
        required: false,
        type: 'textarea',
        defaultOption: null,
        validation: [],
        description: 'Template for automatically generating the name of proposal items.',
        unsafe: true,
        unsafeReason:
            'Template for automatically generating the name of proposal items. Will change the naming convention of all new proposal items',
    },
    feature_flags: {
        label: 'Feature Flags',
        required: false,
        type: 'featureFlagList',
        defaultOption: [],
        validation: [],
        feature_flags: true,
    },
    reach_synchronization_enabled: {
        label: 'Enable Reach',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description:
            'Please check if synchronizing with a Reach Publisher and need to input a Reach Publisher ID.',
        aquarius: true,
    },
    reach_publisher_id: {
        label: 'Reach Publisher ID',
        required: false,
        type: 'number',
        defaultOption: null,
        validation: ['Number'],
        description:
            "Please make sure you select the right Publisher ID before saving. A wrong ID may synchronize the wrong screen's data to a Publisher.",
        aquarius: true,
    },
    production_dsp_scopes: {
        label: 'Production DSP Scopes',
        required: false,
        type: 'enum',
        defaultOption: [],
        options: Object.values(PRODUCTION_DSP_SCOPES_OPTIONS),
        multiple: true,
        validation: [],
        description:
            'Please select the scopes of DSPs that customer can transact with on programmatic. Use “Broadsign Auto-Bidder” only for the onboarding. Please remove it after.',
        aquarius: true,
    },
    header_bidder_enabled: {
        label: 'Enable Header Bidder',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description:
            'Enable this if the customer subscribed to the Header Bidder feature. This will display the “Vistar ID” and “Hivestack ID” fields on the inventory.',
        aquarius: true,
    },
    use_player_identifiers_in_reach_ad_requests: {
        label: 'Disable Unified Inventory',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description:
            'Check off if Org will not have access to Inventory management in Broadsign Platform.',
        aquarius: true,
    },
    broadsign_static_enabled: {
        label: 'Enable Broadsign Static',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description:
            'Activate the checkbox to add a Broadsign Static widget inside the Broadsign Platform.',
        aquarius: true,
    },
    static_tenant_url: {
        label: 'Static Tenant URL',
        required: false,
        type: 'text',
        defaultOption: null,
        validation: [],
        description:
            'Insert the Static Tenant URL so users are properly redirected when clicking on the Broadsign Static' +
            ' widget inside the Broadsign Platform. Ex: customerA.ayudapreview.com',
        aquarius: true,
    },
    unified_static_enabled: {
        label: 'Enable Unified Broadsign Static',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description: 'Not available for use yet.',
        aquarius: true,
    },
    non_guaranteed_campaign_management_enabled: {
        label: 'Enable Contextual Campaigns',
        required: false,
        type: 'boolean',
        defaultOption: false,
        validation: [],
        description:
            'This will allow that customer to create and manage contextual campaigns from the platform. ' +
            'Please activate that module only for eligible Aquarius customers.',
        warningMessage:
            'Make sure the primary currency value is valid as it cannot be changed afterwards!',
        aquarius: true,
    },
    non_guaranteed_campaign_management_creation_tm: {
        required: false,
        defaultOption: null,
        validation: [],
    } /* hack-ish way to make the new field work against validation */,
};

const INVENTORY_TYPES = {
    static: 'static_inventory',
    digital: 'digital_inventory',
};

const VALIDATION_MAP_HELPERS = ['enable_integration_settings', 'listeners', 'salesforce_instance'];

const VALIDATION_ERRORS = {
    requiredError: 'Value is required',
    typeError: type => `Please enter ${type} only`,
    lengthError: length => `Max length of ${length} characters is allowed`,
    errorMax: value => `Max value of ${value} is allowed`,
    errorMin: value => `Min value of ${value} is allowed`,
    requiredErrorFooter: 'Please fill all required fields',
    serverErrorFooter: 'Error on Processing Request',
    repeatedValue: 'Repeated values',
    inventoryError: 'Must select at least one type of inventory',
    textError: 'Please provide a value',
    JSONError: 'Invalid format',
};

const ALLOWED_KEYS = {
    backpace: 8,
    tab: 9,
    enter: 13,
    ArrowLeft: 37,
    ArrowRight: 39,
    delete: 46,
};

const ALLOWED_FLOAT_KEYS = {
    backpace: 8,
    tab: 9,
    enter: 13,
    ArrowLeft: 37,
    ArrowRight: 39,
    delete: 46,
    '.': 190,
};

const FORBIDDEN_KEYS = {
    e: 69,
    E: 69,
    NumpadSubtract: 109,
    '.': 190,
    '+': 187,
    ',': 188,
    NumpadAdd: 107,
};

const FORBIDDEN_FLOAT_KEYS = {
    e: 69,
    E: 69,
    NumpadSubtract: 109,
    '+': 187,
    ',': 188,
    '-': 189,
    NumpadAdd: 107,
};

const SPACER_KEYS = {
    tab: 9,
    enter: 13,
    space: 32,
};

export {
    VALIDATION_MAP,
    VALIDATION_MAP_HELPERS,
    VALIDATION_ERRORS,
    ALLOWED_KEYS,
    ALLOWED_FLOAT_KEYS,
    FORBIDDEN_KEYS,
    FORBIDDEN_FLOAT_KEYS,
    SPACER_KEYS,
    INVENTORY_TYPES,
    BILLING_TYPE_OPTIONS,
    PRIMARY_CURRENCY_OPTIONS,
    CMS_OPTIONS,
    PRODUCTION_DSP_SCOPES_OPTIONS,
};
