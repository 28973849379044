const variables = {
    /* Colors */
    backdrop: 'rgba(30, 30, 30, 0.4)',
    black: '#000',
    borderInput: '#bbb',
    borderInputFocus: '#777',
    borderLight: '#ddd',
    boxShadow: 'rgba(119, 119, 119, .25)',
    darkBlue: '#001464',
    dashboardBackground: '#fafafa',
    defaultText: 'rgba(0, 0, 0, 0.7)',
    disabled: '#ebebe4',
    disabledText: '#999',
    errorBackground: '#fff6f6',
    errorBorder: '#ec9195',
    errorText: '#f21b23',
    grayBackground: '#e8e8e8',
    loginButton: '#ff0000',
    loginButtonActive: '#a00',
    primary: '#00a8ff',
    primarybutton: '#4aa6f9',
    primarybuttonpressed: '#3b87c8',
    removeButton: '#ec9195',
    removeButtonActive: '#ff0000',
    successText: '#06cd30',
    warning: '#ffb718',
    warningButton: '#ff0000',
    warningButtonActive: '#a00',
    white: '#fff',

    /* Fonts */
    brandFont: '"Roboto", Helvetica, Arial, sans-serif',
    lato: '"Lato", Helvetica, Arial, sans-serif',
};

module.exports = variables;
