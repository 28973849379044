import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as getDomainsActions from '../../actions/getDomainsActions';
import * as commonActions from '../../actions/commonActions';

import s from './domainHistory.css';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Button from '../Button/Button';
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';

export class DomainHistory extends Component {
    constructor(props) {
        super(props);

        this.getHistory = this.getHistory.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
    }

    componentDidMount() {
        this.getHistory(1);
    }

    componentWillUnmount() {
        const { setServerErrors } = this.props;
        setServerErrors('');
    }

    getHistory(page) {
        const { getDomainHistory, targetDomain } = this.props;
        getDomainHistory(targetDomain.id, page);
    }

    handleServerErrors() {
        const { serverError } = this.props;

        const errors = {
            footer: Object.values(serverError)[0],
        };
        return errors;
    }

    handleReturn() {
        const { history } = this.props;
        history.push('/domains');
    }

    handleTableRows() {
        const { domainHistory } = this.props;

        let rows = [];
        const placeholder = '*';

        if (domainHistory.results) {
            rows = domainHistory.results.map(data => {
                const modifiedData = {};
                Object.keys(data.fields).forEach(field => {
                    if (field === 'password') {
                        modifiedData[field] = placeholder.repeat(data.fields[field].length);
                    } else {
                        modifiedData[field] = JSON.stringify(data.fields[field]);
                    }
                });

                return {
                    modification_tm: data.modification_tm,
                    email: data.email,
                    fields: {
                        action:
                            data.action && data.resource
                                ? `${[data.action]} ${data.resource}`
                                : 'N/A',
                        ...modifiedData,
                    },
                };
            });
        }
        return rows;
    }

    render() {
        const serverErrors = this.handleServerErrors();
        const { domainHistory, isLoading, targetDomain } = this.props;
        const rows = this.handleTableRows();

        const fields = [
            {
                name: 'email',
                identifier: 'userEmail',
                cellType: 'medium',
                columnTitle: 'User Email',
            },
            {
                name: 'fields',
                identifier: 'modifiedFields',
                valueType: 'Object',
                cellType: 'large',
                columnTitle: 'Activity',
            },
            {
                name: 'modification_tm',
                identifier: 'modificationTM',
                valueType: 'Timestamp',
                cellType: 'small',
                columnTitle: 'Date (Local Time)',
            },
        ];

        return (
            <div className={s.domainHistoryWrapper}>
                <div className={s.domainHistory}>
                    <div className={s.titleWrapper}>{`History of the ${
                        targetDomain.name
                    } domain`}</div>
                    <Table rows={rows} fields={fields} />
                    <div className={s.footer}>
                        <div className={s.paginationWrapper}>
                            {domainHistory.totalPages > 0 && (
                                <Pagination
                                    onPageChange={this.getHistory}
                                    disabled={isLoading}
                                    totalPages={domainHistory.totalPages}
                                />
                            )}
                        </div>
                        <div className={s.eventsWrapper}>
                            {serverErrors.footer && (
                                <ErrorMessage
                                    className="domainHistory"
                                    message={serverErrors.footer}
                                />
                            )}
                            <Button
                                className="cancel"
                                type="submit"
                                label="Return"
                                onClick={this.handleReturn}
                                dataCy="return"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DomainHistory.propTypes = {
    getDomainHistory: PropTypes.func.isRequired,
    targetDomain: PropTypes.shape({}),
    serverError: PropTypes.shape({}),
    setServerErrors: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    domainHistory: PropTypes.shape({}),
    isLoading: PropTypes.bool.isRequired,
};

DomainHistory.defaultProps = {
    serverError: {},
    domainHistory: {},
    targetDomain: {},
};

const mapStateToProps = state => {
    return {
        serverError: state.commonReducer.serverError,
        domainHistory: state.getDomainsReducer.domainHistory,
        isLoading: state.commonReducer.isLoading,
        targetDomain: state.domainReducer.targetDomain,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDomainHistory: (id, page) => dispatch(getDomainsActions.getDomainHistory(id, page)),
        setServerErrors: errors => dispatch(commonActions.setServerErrors(errors)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainHistory);
