import React from 'react';
import PropTypes from 'prop-types';

import TableRow from './TableRow';
import s from './table.css';

const Table = props => {
    const renderTableCols = () => {
        const { fields } = props;
        return fields.map(field => (
            <th className={s.tableHeader} key={field.columnTitle}>
                {field.columnTitle}
            </th>
        ));
    };

    const renderTableRows = () => {
        const { rows, fields } = props;

        return rows.map(row => (
            <TableRow key={row.id || row.modification_tm} data={row} fields={fields} />
        ));
    };

    const tableHeader = renderTableCols();
    const tableRows = renderTableRows();

    return (
        <table className={s.table}>
            <thead className={s.tableTitles}>
                <tr>{tableHeader}</tr>
            </thead>
            <tbody>{tableRows}</tbody>
        </table>
    );
};

Table.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Table;
