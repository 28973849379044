const initialState = {
    commonReducer: {
        isLoading: false,
        serverError: {},
    },
    sessionReducer: {
        username: '',
        token: '',
        expiresAt: null,
    },
    domainReducer: {
        data: {},
        isSuccess: false,
        targetDomain: {},
    },
    getDomainsReducer: {
        domains: [],
        domainHistory: {},
    },
};

export default initialState;
