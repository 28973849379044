import * as actions from './actionTypes';
import { parseRequestResponse } from '../utils/url.utils';

const beginLoading = () => {
    return {
        type: actions.SET_LOADING,
        isLoading: true,
    };
};

const endLoading = () => {
    return {
        type: actions.SET_LOADING,
        isLoading: false,
    };
};

const setServerErrors = serverError => {
    const newError = parseRequestResponse(serverError);

    return {
        type: actions.SET_SERVER_ERROR,
        serverError: newError,
    };
};

export { beginLoading, endLoading, setServerErrors };
