import React from 'react';
import PropTypes from 'prop-types';
import s from './tooltip.css';

const Tooltip = props => {
    const { text, className } = props;
    return (
        <div className={className ? `${s[className]} ${s.tooltip}` : `${s.tooltip}`}>{text}</div>
    );
};

Tooltip.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Tooltip.defaultProps = {
    className: '',
};

export default Tooltip;
