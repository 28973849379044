import React from 'react';
import PropTypes from 'prop-types';

import s from './loadingText.css';

const LoadingText = props => {
    const { className, message } = props;
    return (
        <div className={`${s.loadingText} ${s[className]}`}>
            <span className={s.message}>
                {message}
                <span className={s.dot}>.</span>
                <span className={s.dot}>.</span>
                <span className={s.dot}>.</span>
            </span>
        </div>
    );
};

LoadingText.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
};

LoadingText.defaultProps = {
    className: '',
};

export default LoadingText;
