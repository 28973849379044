import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';

import s from './domainList.css';

import * as domainActions from '../../actions/domainActions';
import * as getDomainsActions from '../../actions/getDomainsActions';
import * as commonActions from '../../actions/commonActions';

import Input from '../Input/Input';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';

import variables from '../../css/variables';
import propertySorter from '../../utils/propertiesSorter.utils';

export class DomainList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            openConfirmModal: false,
            domainToChangeActiveStatus: {},
            isSortingByBillingType: false,
            isActiveSelected: true,
        };

        this.closeModal = this.closeModal.bind(this);
        this.getDomains = this.getDomains.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
        this.showAddDomain = this.showAddDomain.bind(this);
        this.generateDomainList = this.generateDomainList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleConfirmDomainActiveStatusChange = this.handleConfirmDomainActiveStatusChange.bind(
            this
        );
        this.handleCancelDomainActiveStatusChange = this.handleCancelDomainActiveStatusChange.bind(
            this
        );
        this.emitChangeDebounced = debounce(this.emitChange, 10);
        this.handleSorting = this.handleSorting.bind(this);
        this.handleHistory = this.handleHistory.bind(this);
        this.handleActiveInactive = this.handleActiveInactive.bind(this);
        this.handleReactivate = this.handleReactivate.bind(this);
    }

    componentDidMount() {
        const { setTargetDomain } = this.props;
        this.getDomains();
        setTargetDomain({});
    }

    getDomains() {
        const { getDomainList } = this.props;
        getDomainList();
    }

    handleEdit(domain) {
        const { setTargetDomain, history } = this.props;
        setTargetDomain(domain);
        history.push(`/domains/${domain.id}/edit`);
    }

    handleAddUser(domain) {
        const { setTargetDomain, history } = this.props;
        setTargetDomain(domain);
        history.push(`/domains/${domain.id}/add-user`);
    }

    handleHistory(domain) {
        const { setTargetDomain, history } = this.props;
        setTargetDomain(domain);
        history.push(`/domains/${domain.id}/history`);
    }

    handleChange(e) {
        const { value } = e.target;
        this.emitChangeDebounced(value);
    }

    emitChange(value) {
        this.setState({
            searchValue: value,
        });
    }

    handleSorting() {
        this.setState(prevState => ({
            isSortingByBillingType: !prevState.isSortingByBillingType,
        }));
    }

    handleActiveInactive() {
        this.setState(prevState => ({
            isActiveSelected: !prevState.isActiveSelected,
        }));
    }

    handleDeactivate(domain) {
        this.setState({
            openConfirmModal: true,
            domainToChangeActiveStatus: domain,
        });
    }

    handleReactivate(domain) {
        this.setState({
            openConfirmModal: true,
            domainToChangeActiveStatus: domain,
        });
    }

    handleConfirmDomainActiveStatusChange() {
        const { domainToChangeActiveStatus, isActiveSelected } = this.state;
        const { deactivateDomain, reactivateDomain } = this.props;

        if (isActiveSelected) {
            deactivateDomain(domainToChangeActiveStatus.id);
        } else {
            const data = {
                active: true,
            };
            reactivateDomain(data, domainToChangeActiveStatus.id);
        }

        this.setState({
            openConfirmModal: false,
        });
    }

    handleCancelDomainActiveStatusChange() {
        this.setState({
            openConfirmModal: false,
            domainToChangeActiveStatus: {},
        });
    }

    clearSearch() {
        this.setState({
            searchValue: '',
        });
    }

    showAddDomain() {
        const { history } = this.props;
        history.push('/domains/add');
    }

    closeModal() {
        const { resetForm } = this.props;
        resetForm();
        this.getDomains();
        this.setState({
            domainToChangeActiveStatus: {},
        });
    }

    generateDomainList(isActiveSelected) {
        const { domains } = this.props;
        const { searchValue, isSortingByBillingType } = this.state;
        const filterBy = isSortingByBillingType ? 'billing_type' : 'name';

        const selectedDomains = [];
        domains.forEach(domain => {
            if (domain.active === isActiveSelected) {
                selectedDomains.push(domain);
            }
        });

        let newDomainList;

        if (searchValue === '') {
            newDomainList = selectedDomains;
        } else {
            newDomainList = selectedDomains.filter(
                domain =>
                    (domain.name &&
                        domain.name.toLowerCase().includes(searchValue.toLowerCase())) ||
                    (domain.provider_domain_id && domain.provider_domain_id.includes(searchValue))
            );
        }

        return propertySorter(newDomainList, filterBy);
    }

    handleServerErrors() {
        const { serverError } = this.props;

        const errors = {
            header: Object.values(serverError)[0],
        };
        return errors;
    }

    render() {
        const { isSuccess } = this.props;
        const {
            searchValue,
            openConfirmModal,
            domainToChangeActiveStatus,
            isSortingByBillingType,
            isActiveSelected,
        } = this.state;

        const serverErrors = this.handleServerErrors();

        const fields = [
            {
                name: 'name',
                identifier: 'domainName',
                cellType: 'large',
                columnTitle: 'Domain Name',
            },
            {
                name: 'provider_domain_id',
                identifier: 'domainID',
                cellType: 'large',
                columnTitle: 'Provider ID',
            },
            {
                name: 'billing_type',
                identifier: 'domainBillingType',
                valueType: 'SnakeCase',
                labelType: 'Timestamp',
                label: 'creation_tm',
                columnTitle: 'Billing Type',
            },
            {
                name: 'actions',
                identifier: 'domainActions',
                columnTitle: 'Actions',
                actions: [
                    { type: 'edit', handler: this.handleEdit, label: 'Edit' },
                    { type: 'addUser', handler: this.handleAddUser, label: 'Add user' },
                    { type: 'history', handler: this.handleHistory, label: 'History' },
                    {
                        type: isActiveSelected ? 'deactivate' : 'reactivate',
                        handler: isActiveSelected ? this.handleDeactivate : this.handleReactivate,
                        label: isActiveSelected ? 'Deactivate' : 'Reactivate',
                        fill: isActiveSelected ? 'warningButton' : 'successText',
                    },
                ],
            },
        ];

        const rows = this.generateDomainList(isActiveSelected);

        return (
            <div className={s.domainListWrapper} id="domainList">
                <div className={s.domainList} data-cy="domainList">
                    <div className={s.header}>
                        {serverErrors.header && (
                            <ErrorMessage
                                className="domainListError"
                                message={serverErrors.header}
                            />
                        )}
                        <div className={s.headerActions}>
                            <Input
                                type="text"
                                containerClass="searchDomainContainer"
                                onChange={this.handleChange}
                                name="searchDomain"
                                value={searchValue}
                                className="searchDomain"
                                placeholder="Search for domain name or BSS ID"
                                dataCy="searchDomain"
                                icon="clear"
                                onClick={this.clearSearch}
                                fill={variables.defaultText}
                                label=""
                            />
                            <Button
                                label={`Sort by ${
                                    isSortingByBillingType ? 'Name' : 'Billing Type'
                                }`}
                                className="sortDomainButton"
                                dataCy="sortDomainButton"
                                onClick={this.handleSorting}
                            />
                            <Button
                                label="Add new domain"
                                className="addDomainButton"
                                dataCy="addDomainButton"
                                onClick={this.showAddDomain}
                            />
                            <Button
                                label={isActiveSelected ? 'Show inactive' : 'Show active'}
                                className="activeInactiveButton"
                                dataCy="activeInactiveButton"
                                onClick={this.handleActiveInactive}
                            />
                        </div>
                    </div>

                    <Table rows={rows} fields={fields} />
                    {openConfirmModal && (
                        <Modal className="warningModal">
                            <div className={s.confirmationModalTitle}>
                                {`Are you sure you want to ${
                                    isActiveSelected ? 'deactivate' : 'reactivate'
                                } the domain ${domainToChangeActiveStatus.name} (provider ID ${
                                    domainToChangeActiveStatus.provider_domain_id
                                })?`}
                            </div>
                            <div className={s.confirmActions}>
                                <Button
                                    className="cancel"
                                    dataCy="cancel"
                                    label="Cancel"
                                    onClick={this.handleCancelDomainActiveStatusChange}
                                />
                                <Button
                                    className="warning"
                                    dataCy="confirm"
                                    label="Confirm"
                                    onClick={this.handleConfirmDomainActiveStatusChange}
                                />
                            </div>
                        </Modal>
                    )}

                    {isSuccess && (
                        <Modal onClose={this.closeModal}>{`The domain ${
                            domainToChangeActiveStatus.name
                        } (provider ID ${domainToChangeActiveStatus.provider_domain_id}) was ${
                            isActiveSelected ? 'deactivated' : 'reactivated'
                        }`}</Modal>
                    )}
                </div>
            </div>
        );
    }
}

DomainList.propTypes = {
    getDomainList: PropTypes.func.isRequired,
    domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    history: PropTypes.shape({}).isRequired,
    deactivateDomain: PropTypes.func.isRequired,
    reactivateDomain: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    serverError: PropTypes.shape({}),
    setTargetDomain: PropTypes.func.isRequired,
    targetDomain: PropTypes.shape({}),
};

DomainList.defaultProps = {
    serverError: {},
    targetDomain: {},
};

const mapStateToProps = state => {
    return {
        serverError: state.commonReducer.serverError,
        domains: state.getDomainsReducer.domains,
        isSuccess: state.domainReducer.isSuccess,
        targetDomain: state.domainReducer.targetDomain,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDomainList: () => dispatch(getDomainsActions.getDomainList()),
        deactivateDomain: id => dispatch(domainActions.deactivateDomain(id)),
        reactivateDomain: (data, id) => dispatch(domainActions.reactivateDomain(data, id)),
        setSuccess: () => dispatch(domainActions.setSuccess()),
        resetForm: () => dispatch(domainActions.resetForm()),
        setServerErrors: errors => dispatch(commonActions.setServerErrors(errors)),
        setTargetDomain: domain => dispatch(domainActions.setTargetDomain(domain)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainList);
