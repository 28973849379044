import { VALIDATION_MAP } from './domainMap.utils';

const expectedKeys = [
    'active',
    'name',
    'id',
    'billing_type',
    'toc',
    'creation_tm',
    'expiration_date',
    'feature_flags',
];
const expectedSettingsKeys = [
    'timezone',
    'calculate_screen_contribution',
    'cpm_override',
    'default_slot_duration',
    'default_sov_value',
    'underperforming_rebalancing_threshold_pct',
    'overperforming_rebalancing_threshold_pct',
    'skin_filter_criteria_ids',
    'whitelist_containers',
    'reservation_category_blacklist',
    'allow_explicit_undersaturation',
    'bypass_google_address',
    'react_inventory_enabled',
    'proposal_item_name_template',
    'network_criteria_containers',
    'reach_synchronization_enabled',
    'reach_publisher_id',
    'use_player_identifiers_in_reach_ad_requests',
    'broadsign_static_enabled',
    'static_tenant_url',
    'unified_static_enabled',
    'header_bidder_enabled',
    'non_guaranteed_campaign_management_enabled',
    'primary_currency',
    'additional_currencies',
];

const expectedOrganizationSettingsKeys = [
    'cms',
    'non_guaranteed_campaign_management_creation_tm',
    'production_dsp_scopes',
];

const isObjectEmpty = obj => {
    return obj ? Object.keys(obj).length === 0 : true;
};

const parseDomainList = domains => {
    const newListDomains = [];

    domains.forEach(domain => {
        const parsedDomain = {};

        expectedKeys.forEach(key => {
            if (key === 'id') {
                parsedDomain[key] = domain[key] ? domain[key] : '0';
            } else if (key === 'expiration_date' || key === 'active') {
                parsedDomain[key] = domain[key];
            } else if (key === 'feature_flags') {
                parsedDomain[key] = domain[key] ? domain[key] : [];
            } else {
                parsedDomain[key] = domain[key] ? domain[key] : '';
            }
        });

        if (!isObjectEmpty(domain.integration_settings)) {
            if (domain.integration_settings.listeners !== null) {
                parsedDomain.listeners = JSON.stringify(domain.integration_settings.listeners);
            } else {
                parsedDomain.listeners = VALIDATION_MAP.listeners.defaultOption;
            }

            parsedDomain.salesforce_instance = domain.integration_settings.salesforce_instance;

            parsedDomain.enable_integration_settings =
                (domain.integration_settings.listeners !== null &&
                    parsedDomain.listeners.length > 0) ||
                (domain.integration_settings.salesforce_instance !== null &&
                    parsedDomain.salesforce_instance.length > 0);

            parsedDomain.enable_audit_log = domain.integration_settings.audit_log_enabled;
        }

        if (domain.settings) {
            expectedSettingsKeys.forEach(key => {
                parsedDomain[key] = domain.settings[key];
            });
        }

        if (domain.organization_settings) {
            expectedOrganizationSettingsKeys.forEach(key => {
                parsedDomain[key] = domain.organization_settings[key];
            });
        }

        if (domain.domain_mapping) {
            domain.domain_mapping.forEach(mapping => {
                if (mapping.provider === 'bss') {
                    parsedDomain.provider_domain_id = `${mapping.provider_domain_id}`;
                    parsedDomain.digital_inventory = true;
                }

                if (mapping.provider === 'static') {
                    parsedDomain.static_inventory = true;
                }
            });
        }

        newListDomains.push(parsedDomain);
    });

    return newListDomains;
};

export default parseDomainList;
