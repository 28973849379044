import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, { Component } from 'react';

import './SingleDatePickerOverwrite.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import s from './DatePicker.css';
import IconWithTooltip from '../Icon/IconWithTooltip';

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: props.value,
            focusedInput: false,
        };

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFocusChange = this.handleFocusChange.bind(this);
    }

    handleDateChange(date) {
        const { onDateChange } = this.props;
        const value = date
            ? moment.utc(moment(date).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:SS')
            : null;

        this.setState(
            {
                selectedDate: value,
            },
            () => {
                const { selectedDate } = this.state;
                onDateChange(selectedDate);
            }
        );
    }

    handleFocusChange({ focused }) {
        this.setState({ focusedInput: focused });
    }

    render() {
        const { focusedInput, selectedDate } = this.state;
        const { name, labelText, description, error, innerRef, disabled, dataCy } = this.props;
        const date = selectedDate ? moment.utc(moment(selectedDate).format('YYYY-MM-DD')) : null;

        return (
            <div className={s.container} ref={innerRef} data-cy={dataCy}>
                <label htmlFor={name} className={s.label}>
                    {labelText}
                </label>
                <SingleDatePicker
                    date={date}
                    onDateChange={this.handleDateChange}
                    focused={focusedInput}
                    onFocusChange={this.handleFocusChange}
                    showClearDate
                    placeholder=""
                    disabled={disabled}
                    displayFormat={() => moment.localeData().longDateFormat('L')}
                />
                {error && <ErrorMessage message={error} className="fieldError" />}
                {description && (
                    <IconWithTooltip description={description} name={name} icon="help" />
                )}
            </div>
        );
    }
}

DatePicker.propTypes = {
    labelText: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    description: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    disabled: PropTypes.bool,
    dataCy: PropTypes.string,
};

DatePicker.defaultProps = {
    error: '',
    labelText: '',
    description: '',
    name: '',
    value: null,
    innerRef: null,
    disabled: false,
    dataCy: '',
};

export default DatePicker;
