const sortKeys = property => {
    const sorted = (a, b) => {
        if (b[property] && a[property]) {
            const propA = a[property].toString().toLowerCase();
            const propB = b[property].toString().toLowerCase();

            if (propA < propB) return -1;
            if (propA > propB) return 1;
            return 0;
        }
        return null;
    };

    return sorted;
};

const propertiesSorter = (myArray, property) => {
    return myArray.sort(sortKeys(property));
};

export default propertiesSorter;
