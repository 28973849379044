import axios from 'axios';
import * as actions from './actionTypes';
import { beginLoading, endLoading, setServerErrors } from './commonActions';

import parseDomainList from '../utils/parseDomainList.utils';

import { baseHeaderWithAuth, domainURL } from '../utils/url.utils';

import store from '../store/store';

const setDomainList = domains => {
    const newListDomains = parseDomainList(domains);

    return {
        type: actions.SET_DOMAIN_LIST,
        domains: newListDomains,
    };
};

const setDomainHistory = history => {
    return {
        type: actions.SET_DOMAIN_HISTORY,
        domainHistory: {
            totalPages: history.total_pages,
            results: history.results,
        },
    };
};

const getDomainList = () => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .get(`${domainURL}`, {
                headers: baseHeaderWithAuth(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch(setDomainList(response.data));
                    dispatch(endLoading());
                } else {
                    dispatch(setServerErrors('server error'));
                    dispatch(endLoading());
                }
            })
            .catch(error => {
                dispatch(setServerErrors(error));
                dispatch(endLoading());
            });
    };
};

const getDomainHistory = (id, page) => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .get(`${domainURL}${id}/history/?page=${page}`, {
                headers: baseHeaderWithAuth(token),
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch(setDomainHistory(response.data));
                    dispatch(endLoading());
                } else {
                    dispatch(setServerErrors('server error'));
                    dispatch(endLoading());
                }
            })
            .catch(error => {
                dispatch(setServerErrors(error));
                dispatch(endLoading());
            });
    };
};

export { setDomainList, getDomainList, setDomainHistory, getDomainHistory };
