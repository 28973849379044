const ROLE_OPTIONS = {
    admin: 'Admin',
    manager: 'Manager',
    sales: 'Sales',
    sales_coordinator: 'Sales Coordinator',
    services: 'Services',
};

const USER_VALIDATION_MAP = {
    name: {
        component: 'input',
        label: 'Name *',
        required: true,
        type: 'text',
        validation: ['Required'],
    },

    email: {
        component: 'input',
        label: 'Email *',
        required: true,
        type: 'email',
        validation: ['Required', 'Email'],
    },

    password1: {
        component: 'input',
        label: 'Password *',
        required: true,
        type: 'password',
        validation: ['Required', 'Password1'],
    },

    password2: {
        component: 'input',
        label: 'Repeat Password *',
        required: true,
        type: 'password',
        validation: ['Password2'],
    },

    role: {
        component: 'select',
        label: 'Direct Role *',
        required: true,
        type: 'enum',
        defaultOption: '',
        options: Object.values(ROLE_OPTIONS),
        validation: ['Required'],
    },

    phone: {
        component: 'input',
        label: 'Phone',
        required: false,
        type: 'telephone',
        validation: [],
    },
};

const USER_VALIDATION_ERRORS = {
    requiredError: 'Value is required',
    emailError: 'Invalid Email',
    passwordError: 'Password must be at least 8 characters long',
    repeatPasswordError: 'Passwords must be identical',
    serverErrorFooter: 'Error on Processing Request',
};

export { USER_VALIDATION_MAP, USER_VALIDATION_ERRORS, ROLE_OPTIONS };
