import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ConnectedApp from './App';
import store from './store/store';

import history from './history/history';

import './index.css';

render(
    <Provider store={store}>
        <Router history={history}>
            <ConnectedApp />
        </Router>
    </Provider>,
    document.getElementById('root')
);

if (window.Cypress) {
    window.store = store;
}
