import React from 'react';

import PropTypes from 'prop-types';
import s from './list.css';

const List = props => {
    const renderItems = ({ items }) => {
        return Object.keys(items).map(key => (
            <li key={key} className={s.listItem}>{`${key}: ${items[key]}`}</li>
        ));
    };

    const list = renderItems(props);

    return <ul className={s.listContainer}>{list}</ul>;
};

List.propTypes = {
    items: PropTypes.shape({}).isRequired,
};

export default List;
