import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../Button/IconButton';
import variables from '../../css/variables';

import s from './tableAction.css';

class TableAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({
            isHovered: true,
        });
    }

    handleMouseLeave() {
        this.setState({
            isHovered: false,
        });
    }

    render() {
        const { isHovered } = this.state;
        const { onClick, label, action, fill } = this.props;

        return (
            <td
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                className={`${s.actionCell}`}
            >
                <IconButton
                    className={`${action}Button`}
                    onClick={onClick}
                    dataCy={`${action}Button`}
                    icon={action}
                    fill={variables[fill]}
                />

                {isHovered && <Tooltip text={label} />}
            </td>
        );
    }
}

TableAction.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    fill: PropTypes.string,
};

TableAction.defaultProps = {
    fill: variables.black,
};

export default TableAction;
