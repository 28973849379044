import React from 'react';
import PropTypes from 'prop-types';

import s from './warningMessage.css';

const WarningMessage = props => {
    const { message, className } = props;
    let classes = `${s.warningMessage}`;
    if (className) {
        classes = `${s.warningMessage} ${s[className]}`;
    }
    return (
        <div className={classes} data-cy="warningMessage">
            {message}
        </div>
    );
};

WarningMessage.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
};

WarningMessage.defaultProps = {
    className: '',
};

export default WarningMessage;
