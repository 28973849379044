import React from 'react';
import PropTypes from 'prop-types';
import IconList from './IconList';
import variables from '../../css/variables';

const Icon = props => {
    const { icon, size, fill } = props;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 512 512"
            className={`icon icon-${icon}`}
            fill={fill}
        >
            <path d={IconList[icon]} />
        </svg>
    );
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    fill: PropTypes.string,
};

Icon.defaultProps = {
    size: 20,
    fill: variables.defaultText,
};

export default Icon;
