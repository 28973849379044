import React, { Component } from 'react';
import PropTypes from 'prop-types';

import s from './modal.css';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };

        this.modalRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        const { onClose } = this.props;
        if (onClose) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        const { onClose } = this.props;
        if (onClose) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside(e) {
        const { onClose } = this.props;

        if (onClose) {
            if (this.modalRef.current && !this.modalRef.current.contains(e.target)) {
                document.removeEventListener('mousedown', this.handleClickOutside);
                this.setState({
                    isOpen: false,
                });
                onClose();
            }
        }
    }

    handleClose() {
        const { onClose } = this.props;

        if (onClose) {
            this.setState({
                isOpen: false,
            });

            onClose();
        }
    }

    render() {
        const { children, className, onClose } = this.props;

        const { isOpen } = this.state;

        let modalClass = s.container;

        if (className && s[className]) {
            modalClass = `${modalClass} ${s[className]}`;
        }

        return (
            isOpen && (
                <div className={s.backdrop}>
                    <div
                        className={modalClass}
                        id="modal"
                        ref={this.modalRef}
                        role="presentation"
                        onClick={this.handleClickOutside}
                        onMouseDown={this.handleMouseDown}
                        data-cy="modal"
                    >
                        {children}

                        {onClose && (
                            <button
                                type="button"
                                className={s.close}
                                onClick={this.handleClose}
                                data-cy="closeModal"
                            >
                                X
                            </button>
                        )}
                    </div>
                </div>
            )
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func,
};

Modal.defaultProps = {
    className: '',
    onClose: undefined,
};

export default Modal;
