import axios from 'axios';
import * as actions from './actionTypes';
import { beginLoading, endLoading, setServerErrors } from './commonActions';
import { baseHeaderWithAuth, domainURL, providerURL, userURL } from '../utils/url.utils';

import store from '../store/store';

const setSavedData = data => {
    return {
        type: actions.SET_SAVED_DATA,
        data,
    };
};

const setSavedHasValidProviderId = hasValidProviderId => {
    return {
        type: actions.SET_SAVED_HAS_VALID_PROVIDER_ID,
        hasValidProviderId,
    };
};

const setSavedResponse = response => {
    return {
        type: actions.SET_SAVED_RESPONSE,
        response,
    };
};
const setSuccess = () => {
    return {
        type: actions.SET_SUCCESS,
        isSuccess: true,
    };
};

const resetForm = () => {
    return {
        type: actions.SET_SUCCESS,
        isSuccess: false,
    };
};

const setTargetDomain = domain => {
    return {
        type: actions.SET_TARGET_DOMAIN,
        targetDomain: domain,
    };
};

const addNewDomain = data => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .post(`${domainURL}`, data, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 201) {
                    dispatch(endLoading());
                    dispatch(setSavedData(data));
                    dispatch(setServerErrors(''));
                    dispatch(setSuccess());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const editDomain = (data, id) => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .put(`${domainURL}${id}/`, data, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 200) {
                    dispatch(endLoading());
                    dispatch(setSavedData(data));
                    dispatch(setServerErrors(''));
                    dispatch(setSuccess());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const addUser = data => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .post(`${userURL}`, data, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 201) {
                    dispatch(endLoading());
                    dispatch(setSavedData(data));
                    dispatch(setServerErrors(''));
                    dispatch(setSuccess());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const deactivateDomain = id => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        return axios
            .delete(`${domainURL}${id}/`, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 200) {
                    dispatch(endLoading());
                    dispatch(setServerErrors(''));
                    dispatch(setSuccess());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const reactivateDomain = (data, id) => {
    const { token } = store.getState().sessionReducer;

    return dispatch => {
        dispatch(beginLoading());
        return axios
            .patch(`${domainURL}${id}/`, data, { headers: baseHeaderWithAuth(token) })
            .then(response => {
                if (response.status === 200) {
                    dispatch(endLoading());
                    dispatch(setServerErrors(''));
                    dispatch(setSuccess());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const checkHasFiller = (providerId, targetDomain) => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        let url;
        if (targetDomain.whitelist_containers) {
            url = `${domainURL}has_filler/?provider_id=${providerId}&whitelist_containers=${targetDomain.whitelist_containers.toString()}`;
        } else {
            url = `${domainURL}has_filler/?provider_id=${providerId}`;
        }
        return axios
            .get(url, {
                headers: baseHeaderWithAuth(token),
            })
            .then(response => {
                if (response.status === 200) {
                    const data = { ...targetDomain };
                    data.missingFillers = response.data.missing_fillers;
                    dispatch(setSavedData(data));
                    dispatch(endLoading());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const checkProviderId = providerId => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        const url = `${providerURL}${providerId}/check/`;

        return axios
            .get(url, {
                headers: baseHeaderWithAuth(token),
                validateStatus: status => (status >= 200 && status < 300) || status === 404,
            })
            .then(response => {
                dispatch(setSavedHasValidProviderId(response.status === 200));
                dispatch(endLoading());
            })
            .catch(error => {
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

const getLoopPolicies = (providerId, targetDomain) => {
    const { token } = store.getState().sessionReducer;
    return dispatch => {
        dispatch(beginLoading());
        let url = `${domainURL}non_overbookable_loop_policy/?provider_id=${providerId}`;
        if (targetDomain.whitelist_containers) {
            if (targetDomain.whitelist_containers.length > 0) {
                url = `${domainURL}non_overbookable_loop_policy/?provider_id=${providerId}&container_ids=${targetDomain.whitelist_containers.toString()}`;
            }
        }
        return axios
            .get(`${url}`, {
                headers: baseHeaderWithAuth(token),
            })
            .then(response => {
                if (response.status === 200) {
                    const data = { ...targetDomain };
                    data.getLoopPolicies = response.data.non_overbookable_loop_policies_ids;
                    dispatch(setSavedResponse(data));
                    dispatch(endLoading());
                } else {
                    dispatch(endLoading());
                    dispatch(resetForm());
                }
            })
            .catch(error => {
                dispatch(resetForm());
                dispatch(endLoading());
                dispatch(setServerErrors(error));
            });
    };
};

export {
    addNewDomain,
    addUser,
    editDomain,
    setSavedData,
    setSavedHasValidProviderId,
    setSavedResponse,
    setServerErrors,
    deactivateDomain,
    reactivateDomain,
    setSuccess,
    resetForm,
    setTargetDomain,
    checkHasFiller,
    checkProviderId,
    getLoopPolicies,
};
