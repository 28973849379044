import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import domainReducer from './domainReducer';
import getDomainsReducer from './getDomainsReducer';
import commonReducer from './commonReducer';

const rootReducer = combineReducers({
    commonReducer,
    sessionReducer,
    domainReducer,
    getDomainsReducer,
});

export default rootReducer;
