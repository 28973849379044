import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage/ErrorMessage';

import s from './textArea.css';

class TextArea extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleChange(e) {
        const { onChange } = this.props;
        onChange(e);
    }

    handleFocus(e) {
        const { onFocus } = this.props;
        onFocus(e);
    }

    handleBlur(e) {
        const { onBlur } = this.props;
        onBlur(e);
    }

    render() {
        const {
            id,
            name,
            dataCy,
            labelText,
            value,
            disabled,
            error,
            required,
            innerRef,
        } = this.props;
        return (
            <div className={s.container} ref={innerRef}>
                <label htmlFor={name} className={s.label}>
                    {labelText}
                </label>
                <textarea
                    id={id}
                    className={s.textarea}
                    name={name}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    data-cy={dataCy}
                    value={value}
                    disabled={disabled}
                    required={required}
                />
                {error && <ErrorMessage message={error} className="fieldError" />}
            </div>
        );
    }
}

TextArea.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    dataCy: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    error: PropTypes.string,
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

TextArea.defaultProps = {
    error: '',
    innerRef: null,
};

export default TextArea;
